<template>
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Scan Findings</b-card-title>
        <b-card-text class="mr-25 mb-0">
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in statisticsItems" 
            :key="item.icon"
            md="3"
            sm="6"
            class="mb-2 mb-md-0"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside
  
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
  } from 'bootstrap-vue'
  
  //dompurify prevent xss
  import * as DOMPurify from 'dompurify'  

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
    },
    data() {

      
      return {
        
        statisticsItems: []
  
      }
    },
    mounted() {
     
const userapikey =  localStorage.getItem('userapikey')
const scandomain = localStorage.getItem('scandomain')
const scanid = localStorage.getItem('scanid')

let userapikeysanitized = DOMPurify.sanitize(userapikey);
let scandomainsanitized = DOMPurify.sanitize(scandomain);
let scanidsanitized = DOMPurify.sanitize(scanid);


 this.$http.get(`${process.env.VUE_APP_SECURITYINFINITYFRONTEND_URL}`+'/api/report_dashboard/fetchreport?reporttype=findingstypestatscount&userapikey='+userapikeysanitized+'&scandomain='+scandomainsanitized+'&scanid='+scanidsanitized)
  .then((response) => {

     
  if (response.data.cve == null)
    global.cvecount = 0
  else 
    global.cvecount = response.data.cve

  if (response.data.exposure == null)
    global.exposurecount = 0
  else 
    global.exposurecount = response.data.exposure

  if (response.data.vulnerability == null)
    global.vulnerabilitycount = 0
  else 
    global.vulnerabilitycount = response.data.vulnerability

  if (response.data.misconfig == null)
    global.misconfigcount = 0
  else 
    global.misconfigcount = response.data.misconfig

    setTimeout(()=> {
    //timeout for lazy loading and to load after fetching data only


    this.statisticsItems = [
          {
            icon: 'ZapIcon',
            color: 'light-success',
            title: global.cvecount,
            subtitle: 'CVE',
            customClass: '',
          },
          {
            icon: 'CloudDrizzleIcon',
            color: 'light-primary',
            title: global.vulnerabilitycount,
            subtitle: 'Vulnerabilities',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'ShieldOffIcon',
            color: 'light-info',
            title: global.misconfigcount,
            subtitle: 'Server Misconfiguration',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: global.exposurecount,
            subtitle: 'Data Exposures',
            customClass: 'mb-2 mb-sm-0',
          },

        ]


     })
    }, 1000)

},


  }
  </script>
  