<template>
  <b-card>
    <b-card-title class="mb-1">
      Findings
    </b-card-title>
    <b-card-sub-title class="mb-2">
    </b-card-sub-title>

    <vue-apex-charts
      ref="junoseveritychart"
      type="donut"
      height="350"
      :options="vulnerabilitytypeDonutChartData.donutChart.chartOptions"
      :series="junochartdata"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vulnerabilitytypeDonutChartData from '../juno_components/severityDonutChart.js'

//dompurify prevent xss
import * as DOMPurify from 'dompurify'
//axios
//import axios from 'axios';


export default {



  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {

      
      vulnerabilitytypeDonutChartData,

      //initalizing data for chart.. required 
      //once initialized, data will be read dynamically from mounted() api
     junochartdata: [0,0,0,0],

     //junochartdataoutput:[],



    }
  },
  mounted(){

    const userapikey =  localStorage.getItem('userapikey')
    const scandomain = localStorage.getItem('scandomain')
    const scanid = localStorage.getItem('scanid')

    let userapikeysanitized = DOMPurify.sanitize(userapikey);
    let scandomainsanitized = DOMPurify.sanitize(scandomain);
    let scanidsanitized = DOMPurify.sanitize(scanid);
    
    setTimeout(()=> {


    this.$http.get(`${process.env.VUE_APP_SECURITYINFINITYFRONTEND_URL}`+'/api/report_dashboard/fetchreport?reporttype=findingstypestatscount&userapikey='+userapikeysanitized+'&scandomain='+scandomainsanitized+'&scanid='+scanidsanitized)
      .then((response) => {

         
      if (response.data.cve == null)
        global.cvecount = 0
      else 
        global.cvecount = response.data.cve

      if (response.data.exposure == null)
        global.exposurecount = 0
      else 
        global.exposurecount = response.data.exposure

      if (response.data.vulnerability == null)
        global.vulnerabilitycount = 0
      else 
        global.vulnerabilitycount = response.data.vulnerability

      if (response.data.misconfig == null)
        global.misconfigcount = 0
      else 
        global.misconfigcount = response.data.misconfig




        global.output = [cvecount,exposurecount,vulnerabilitycount,misconfigcount]
        //console.log(global.output)
        //sets the chart values on chart with id  junoseveritychart
        //values will be only accessible when variables are declared as global 
        
        //timeout for lazy loading and to load after fetching data only


        this.$refs.junoseveritychart.updateSeries	(global.output, true )

      })
    }, 5000)

  }
}

  
</script>
