<template>

    <div>

  
      <div>
  
  
      <b-row class="match-height">
  
        <b-col>
          <b-card>
          <scan-statistics />
  
          
  
        </b-card>
  
        </b-col>
      </b-row>
  
  
      <b-row class="match-height">
  
  <b-col>
    <b-card>
    <vulnerability-type-donut-chart />
  
    
  
  </b-card>
  
  </b-col>
  </b-row>
  
  
      
    </div>
  
  </div>
  
  </template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,BRow, BCol 
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VulnerabilityTypeDonutChart from '././juno_screens/VulnerabilityTypeDonutChart.vue'
import ScanStatistics from '././juno_screens/ScanStatistics.vue'





export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    ScanStatistics,
    VulnerabilityTypeDonutChart
},
  data() {
    return {
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

//hides gridelines by the chart --juno fix
.apexcharts-gridline {
    stroke-width: 0px;
}
</style>
