import { $themeColors } from '@themeConfig'
  //dompurify prevent xss
  //import * as DOMPurify from 'dompurify';



export default {


  
  donutChart: {
    //series: [5,5,5,5],
    chartOptions: {
      chart: {
        id: 'junoseveritychart'
      },
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
        "#EA5455", 
        "#2563EB",
        "#00CFE8",      
        "#28C76F"
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}`
                },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Findings',
                formatter() {
                  return ''
                },
              },
            },
          },
        },
      },
      labels: ['CVE','Vulnerabilies', 'Exposure', 'Misconfiguration'],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  }


}